import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledH2 = styled.h2`
  color: #666666;
  text-align: center;
  margin: 0;
  padding: 0 2rem;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: inherit;
  font-size: 1.75rem;
  line-height: 2rem;
`
const Subtitle = ({ text }) => {
  return <StyledH2>{text}</StyledH2>
}

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Subtitle
