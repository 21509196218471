import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, MobileFooter, Button } from "@hemalr/react-blocks"
import styled from "styled-components"
import Logo from "./logo"
import { primaryColor } from "./site-config"

import "./layout.css"

const StyledApp = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

const StyledNavbarLink = styled(Link)`
  color: #677787;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${primaryColor};
  }
`

const StyledFooter = styled.footer`
  margin: auto;
  text-align: center;
  font-size: 0.75rem;
  background: #f9fafa;
  padding: 2rem 0;
  width: 100%;
  font-style: italic;
  @media (max-width: 840px) {
    & {
      margin-bottom: 67px;
    }
  }
`

const StyledBody = styled.main`
  line-height: 1.5rem;
`

const Layout = ({ children }) => (
  <StyledApp>
    <Navbar
      breakpoint="860px"
      logo={
        <Link to="/">
          <Logo />
        </Link>
      }
    >
      <StyledNavbarLink activeStyle={{ color: primaryColor }} to="/">
        HOME
      </StyledNavbarLink>
      <StyledNavbarLink activeStyle={{ color: primaryColor }} to="/contact/">
        CONTACT
      </StyledNavbarLink>
      <StyledNavbarLink activeStyle={{ color: primaryColor }} to="/packages/">
        PACKAGES
      </StyledNavbarLink>
      <Link to="/book">
        <Button text="BOOK NOW" size="1rem" color={primaryColor} rounded />
      </Link>
    </Navbar>
    <StyledBody>{children}</StyledBody>
    <StyledFooter>
      © {new Date().getFullYear()} <br />
      Liability limited by a scheme approved under Professional Standards
      Legislation
    </StyledFooter>
    <MobileFooter
      mapLink="https://goo.gl/maps/15HD5ogJzhCanXVD9"
      email="admin@everythingaccounting.com.au"
      phone="0413 248 459"
    />
  </StyledApp>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
